<template>
  <div>
    <!--Login Section-->
    <section class="login-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="column col-md-6 offset-md-3 col-sm-12 col-xs-12">
            <h2>تسجيل الدخول</h2>

            <!-- Login Form -->
            <div class="login-form">
              <!--Login Form-->
              <form @submit.prevent="login">
                <div class="form-group">
                  <label>أدخل الإيميل أو رقم الموبايل</label>
                  <input
                    v-model="credentials.email"
                    type="text"
                    placeholder="الإيميل أو رقم الموبايل"
                  />
                </div>

                <div class="form-group">
                  <label>أدخل كلمة المرور</label>
                  <input
                    v-model="credentials.password"
                    type="password"
                    placeholder="كلمة المرور"
                  />
                </div>

                <div class="clearfix">
                  <div class="pull-left">
                    <div class="form-group check-box">
                      <input
                        v-model="credentials.remember"
                        type="checkbox"
                        id="account-option-1"
                      />&nbsp; <label for="account-option-1">تذكرنى</label>
                    </div>
                  </div>
                  <div class="pull-right">
                    <div class="form-group no-margin">
                      <button
                        class="theme-btn btn-style-one"
                        type="submit"
                        name="submit-form"
                      >
                        تأكيد
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group clearfix">
                  <router-link to="/reset-password" class="psw pull-left"
                    >نسيت كلمة المرور ؟</router-link
                  >
                </div>
                <div class="form-group clearfix text-center">
                  <router-link to="/register" class="psw"
                    >تسجيل حساب جديد</router-link
                  >
                </div>
              </form>
            </div>
            <!--End Login Form -->
          </div>
        </div>
      </div>
    </section>
    <!--End Login Section-->
  </div>
</template>

<script>
export default {
  name: "login",
  data: function () {
    return {
      credentials: {
        email: "",
        password: null,
        remember: null,
      },
    };
  },
  methods: {
    login() {
      this.$store.dispatch("login", this.credentials);
    },
  },
};
</script>

<style scoped>
.rtl .login-form .form-group {
  text-align: right;
}

@media (min-width: 768px) {
  .offset-md-3 {
    margin-right: 25%;
  }
}
</style>